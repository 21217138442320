import styled from 'styled-components'
import { device } from '../../../theme'

export const SectionWrap = styled.section`
    // @media (prefers-color-scheme: light) {
    //     background-color: #fff;
    // }
    background-color: #fff;
    @media (prefers-color-scheme: dark) {
        background-color: #121212;
    }
    padding-top: 89px;
    padding-bottom: 96px;
    text-align: left;
    @media ${device.medium}{
        padding-top: 72px;
        padding-bottom: 76px;
    }
    @media ${device.small}{
        padding-top: 54px;
        padding-bottom: 56px;
    }
`;