import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import { Container, Row, Col } from '../components/ui/wrapper'
// import Heading from '../components/ui/heading'
import Text from '../components/ui/text'
import { SectionWrap } from '../containers/our-history/intro-area/style'

// import PageHeader from '../components/pageheader'
// import IntroArea from '../containers/our-history/intro-area'
// import HistoryArea from '../containers/our-history/history-area'
// import ContactArea from '../containers/global/contact-area/contact-two'

const HistoryPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO 
            title="Privacy Policy" 
            description="We take the question of security and protection of personal information very seriously."
        />
        <Header />
        {/* <PageHeader
            pageContext={pageContext}
            location={location}
            title="Privacy Policy"
        /> */}
        <main className="site-wrapper-reveal">
            <SectionWrap>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Text>
                                <h2>Privacy Policy</h2>
                                <hr/>
                                <strong>Last updated: October 07, 2020</strong>
                                <hr/>
                                <h5>Introduction</h5><br/>
                                <p>The company LEADSTARSHIP (PVT) LTD (hereinafter referred to as: Sixvertex Software) Company registration number PV00221486 takes the question of security and protection of personal information very seriously. Taking the mentioned into consideration, sixvertex takes special care that users of the website www.sixvertex.com (hereinafter referred to as: users) are entirely informed about rights they might have regarding the collection of their personal information, as well as regarding security measures taken by sixvertex. </p>
                                <p>By accessing and/or using the website www.sixvertex.com, the user agrees to have read, understood and accepted the terms from this non-disclosure statement. If the user does not agree, they should refrain from using the website, leave, and not access and/or use the website www.sixvertex.com, as well as further on not access and/or use the website again.</p><br/>
                                <h5>Amendment to the Privacy Policy</h5>
                                <hr/>
                                <p>
                                sixvertex is authorized to amend the non-disclosure statement at any time by publishing the amended text of the statement on the website www.sixvertex.com. The amendments shall become effective immediately after their publication on the website. Any use of the website by the end user after such publication shall be considered as acceptance of those terms. The user shall periodically re-read the non-disclosure statement to keep informed of any changes.
                                </p>
                                <p>If the user uses the website after the non-disclosure statement has been amended, it shall be presumed that they have learned, understood and fully accepted the amendments. Liability of sixvertex for any damage caused to users or third parties due to such amendments shall be excluded.</p><br/>
                                <h5>Type of Data Collected and Use of the Data Collected</h5>
                                <hr/>
                                <p>sixvertex collects personal information that identifies users of the website www.sixvertex.com. When a user joins in on certain activities on the website www.sixvertex.com, such as product or service subscription, participating in competitions organized by sixvertex, filling out surveys, posting comments, sending feedback to sixvertex, seeking information about sixvertex products and services, applying for jobs, sixvertex has the right to demand the users provide certain information about themselves. The user shall not be obliged to join in on the mentioned activities.</p>
                                <p>If the user chooses to join in, sixvertex shall be authorized to request the user’s personal information, which, depending on the activity, can include: name and surname, address (including postal code), e-mail address, telephone number including mobile phone number, date of birth, place of work and employer information. sixvertex shall be authorized to use the personal information of the user to offer products and/or services, improve the quality of products and/or services, organize competitions, improve the functioning of the website, enhance the advertising and promotional activities, analyse the use of the website and inquire about the user’s experience with third parties. sixvertex shall be authorized to collect information that does not identify a specific end user, including the Uniform Resource Locator (URL) of the website the user visited prior to opening the website of www.sixvertex.com, the URL of the website that the user visited after leaving the website of www.sixvertex.com, the type of browser the user is using and the user’s Internet Protocol (IP) address.</p>
                                <p>Authorized service providers and advertisers may automatically collect this information when visiting the sixvertex website either by means of "cookies" or other tools. sixvertex shall use such information solely to resolve problems, administer the website, analyse trends, collect demographic data, analyse the compliance with the relevant legislation and to cooperate with law enforcement agencies. Depending on the type of activity, some information shall be mandatory and some shall be optional. If the user does not provide the mandatory information, when such is required for an activity, they shall not be allowed to join in on such activity.</p>
                                <p>sixvertex shall not collect the following personal information through its website: name and surname, address, telephone number or online address of the user, unless the user voluntarily submits it, registers with sixvertex or submits the same through a survey, through consent and/or if this is permitted by the relevant laws and regulations regarding personal data protection.</p><br/>
                                <h5>Types of candidate data we collect during recruitment process</h5>
                                <hr/>
                                <p>sixvertex processes all the candidate data you share with us for legitimate human resources purposes during the application or recruitment process. These include identifying and evaluating candidates for job positions at sixvertex; record-keeping related to hiring processes; analyzing the hiring process and outcomes; collecting reference information and/or information received from background checks (where applicable), including information provided by third parties. In addition to using candidate data for the position for which you have applied, sixvertex may retain and use data to consider you for other positions.</p>
                                <p>If you do not want to be considered for other positions or would like to have candidate data removed, you may contact our data officer. Candidate data will be retained and used for a maximum period of 2 years in our database, for recruitment and hiring purposes, or a shorter period if we inform you differently during the hiring process.</p><br/>
                                <h5>Who may have access to candidate data</h5>
                                <hr/>
                                <p>Within the company, candidate data will be shared with all people included in the recruitment process. sixvertex operates in several countries, which means that candidate data may be stored and processed outside of the country or region where it was originally collected. We comply with relevant legislation described in our Privacy policy. Candidate data may be shared with our affiliates, subsidiaries and/or sister companies, to consider you for other current or future jobs and potentially include you in the recruitment process.</p>
                                <p>If you have been referred for a job at sixvertex by a current sixvertex employee, with your consent, we may inform that employee about the progress of your application and let the sixvertex employee know the outcome of the process. In some cases, if it is identified that you have attended the same university/school or shared the same previous employer during the same period as a current sixvertex employee, we may consult with that employee for feedback on you. We use Greenhouse and Google, as our third party data processor to process candidate data on our behalf, as described in their privacy policy.</p><br/>
                                <h5>Where we obtain candidate data</h5>
                                <hr/>
                                <p>We collect candidate data for recruitment and hiring purposes automatically through our application form on this website and angel.co platform. You may follow www.angel.co/privacy to read more about their privacy and terms. The data we collect is personal information you share with us. We also collect personal information from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal information received in the following situations: your referees may disclose personal information about you; we may obtain personal information about you from searching for potential candidates from third party sources, such as LinkedIn and other job sites.</p><br/>
                                <h4>Disclosure of Personal Information</h4>
                                <hr/>
                                <p>sixvertex shall not share personal identification information with other parties, but it may share the information about users with its affiliated companies. sixvertex may use third party services, such as Google Analytics, Google Recaptcha, Facebook Messenger, Netlify, Hotjar, Greenhouse to process personal information on their own behalf. Such service providers are meticulously chosen by sixvertex, especially taking into consideration their compliance with the relevant laws and regulations regarding personal data protection.</p>
                                <p>sixvertex shall be authorized to disclose information about the user only in order to protect the justified rights and interest of sixvertex and others. sixvertex shall be authorised to disclose the user’s personal identification information when it is reasonable to expect that the disclosure will aid the enforcement of the law or when it is necessary due to a court order, or when its disclosure ensures compliance with this non-disclosure statement and other contracts, or to protect the ownership rights of this website, its users or other persons. </p>
                                <p>If the user does not wish their personal information be used in support of the customer relations (in particular for direct marketing or market research), sixvertex shall respect the user’s choice. sixvertex shall not sell or market personal identification information to third parties.</p><br/>
                                <h4>User Rights regarding their Personal Identification Information</h4>
                                <hr/>
                                <p>The user shall be authorised to change any piece of their personal information by sending an e-mail, in which the user shall state their name and surname, address and e-mail, and the information they would like to amend. The user shall be authorised to request their personal identification information be deleted or their processing be limited without unnecessary delay, according to the relevant laws and regulations regarding personal data protection.</p>
                                <p>The user shall be authorised to receive their personal identification information, which they have previously provided to sixvertex, and transfer the same to another data processor. The user shall be authorised to file a complaint regarding the processing of their personal identification information at any time, according to the relevant laws and regulations regarding personal data protection.</p><br/>
                                <h4>User Choice regarding the Collection and Use of Data</h4>
                                <hr/>
                                <p>The user shall have the possibility to request promotional e-mail messages that advertise products and/or services of sixvertex, including exclusive sales and other offers, and/or products and services of affiliated companies. If the user does not want to receive promotional e-mails, they can, at any time after having subscribed to the newsletter, choose the option of cancelling the receipt of promotions via e-mail. When contacting, the user shall state their name, address and e-mail address, and what type of promotional e-mails they no longer want to receive.</p><br/>
                                <h4>Data protection</h4>
                                <hr/>
                                <p>sixvertex shall undertake technical and organisational security measures to protect the personal data from accidental or unlawful destruction, loss or change, and from unauthorised disclosure or access. The user is familiar with the fact that no data transmission through the Internet or any wireless network can be 100% secure.</p>
                                <p>Although sixvertex is carrying out commercially reasonable data protection measures, it cannot guarantee the protection of any information transmitted to or from our website and shall not be liable for the conduct of any third party that shall receive such information.</p><br/>
                                <h4>Data Monitoring</h4>
                                <hr/>
                                <p>sixvertex shall be authorised to store "cookies" on your computer, which contain information about the user and are meant to save time for the user when using the website www.sixvertex.com, and to monitor and direct the user’s interests in order to provide services that are tailored to every individual user. "Cookies" shall also collect non-personal user information.</p>
                                <p>The user may set up his browser to reject the "cookies", in which case some parts of the sixvertex website may not run correctly. sixvertex may also use other standard technologies to monitor the use of the website and promotions.</p><br/>
                                <h4>non-Disclosure Concerning Third Party Websites</h4>
                                <hr/>
                                <p>This non-disclosure statement shall only apply to the use and disclosure of information collected from users by sixvertex. Other websites, which can be accessed through the sixvertex website, have their own non disclosure statements on collecting information and on ways to use their websites and publish content on the same. sixvertex shall not be liable for the methods and terms of operation of third parties.</p><br/>
                                <h4>Minors</h4>
                                <hr/>
                                <p>Minors may only and exclusively participate in promotional activities of sixvertex with parental consent. sixvertex shall not intentionally collect or retain the information/personal data of children under the age of 16, without insisting on prior parental consent. sixvertex shall use or publish the personal information of a child only and within the scope of permission given or granted by the parent, custodian or guardian of the child.</p><br/>
                                <h4>Effectiveness</h4>
                                <hr/>
                                <p>This statement shall be effective until its revocation by sixvertex.</p>
                                </Text>
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
            {/* <IntroArea>ff</IntroArea> */}
            {/* <HistoryArea /> */}
            {/* <ContactArea /> */}
        </main>
        <Footer />
    </Layout>
)

export default HistoryPage
